<template>
  <div class="account_log_page_wrap" >
    <div class="search_wrap" >
      <div class="search_left" >
        <div>搜索</div>
        <input class="search_input" placeholder="请输入订单号" v-model="relationId"/>
      </div>
      <img src="./img/18.png" class="search_icon" @click="reSearch" />
    </div>
    <div class="select_wrap" >
      筛选：
      <div class="level_select_wrap" @click="show = true"  >
        {{ !type ? '类型' : columns[type].name }} <img src="./img/19.png" class="down_icon" />
      </div>

      <div class="level_select_wrap" @click="showTime = true" style="width: 120px;" >
        {{ !time ? '时间' : moment(time).format('YYYY/MM/DD') }} <img src="./img/19.png" class="down_icon" />
      </div>
    </div>
    <div class="content_wrap" @scroll="handleScroll" >
      <div class="account_log_item" v-for="(item, index) in list" :key="index" >
        <div class="account_top" >
          <div>订单号 {{ item.relationId }}</div>
          <div style="color: #03CFAF;" >{{ parseFloat(item.amount) < 0 ? item.amount : '+' + item.amount }}</div>
        </div>
        <div class="account_bottom" >
          <div class="account_tag" style="background: #03CFAF" v-if="item.type == 3 || item.type == 4 || item.type == 5">{{ item.typeStr }}</div>
          <div class="account_tag" style="background: #62BFDA" v-if="item.type == 1 || item.type == 2 || item.type == 7 || item.type == 8" >{{ item.typeStr }}</div>
          <div class="account_tag" style="background: #FF78AB" v-if="item.type == 6 || item.type == 9" >{{ item.typeStr }}</div>
          <div>{{ item.createTime }}</div>
        </div>
      </div>
      <van-empty description="暂无账户记录" v-if="list.length <= 0" />
    </div>

    <van-popup v-model="show" position="bottom">
      <van-picker
        title="类型"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="show = false"
        value-key="name"
      />
    </van-popup>

    <van-popup v-model="showTime" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="下单时间"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="timeConfirm"
        @cancel="showTime = false"
      />
    </van-popup>
  </div>
</template>
<style scoped lang="less" >
@import "index";
</style>
<script>
import {
  getAccountLogList
} from '@/api'
import moment from 'moment'
export default {
  name: 'accountLog',
  data() {
    return {
      page: 1,
      pageSize: 10,
      list: [],
      show: false,
      columns: [
        { id: '', name: '全部' },
        { id: 1, name: '平级奖励' },
        { id: 2, name: '推荐奖励' },
        { id: 3, name: '提现' },
        { id: 4, name: '提现返还' },
        { id: 5, name: '账户调整' },
        { id: 6, name: '退款' },
        { id: 7, name: '设备平级奖励' },
        { id: 8, name: '设备推荐奖励' },
        { id: 9, name: '设备退款' },
      ],
      type: '',
      relationId: '',
      showTime: false,
      currentDate: new Date(),
      minDate: new Date(1970, 0, 1),
      maxDate: new Date(2099, 11, 30),
      time: '',
      moment
    }
  },
  methods: {
    timeConfirm(event) {
      this.currentDate = new Date(event)
      this.time = moment(event).format('YYYY-MM-DD')
      this.showTime = false
      this.reSearch()
    },
    onConfirm(event) {
      this.type = event.id
      this.show = false
      this.reSearch()
    },
    handleScroll(event) {
      const {scrollTop, clientHeight, scrollHeight} = event.target
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        this.page++
        this.getAccountLogList()
      }
    },
    getAccountLogList() {
      const { page, pageSize, type, relationId } = this
      let values = {
        page,
        pageSize,
        type,
        relationId
      }

      if(this.time) {
        values.startTime = this.time + ' 00:00:00'
        values.endTime = this.time + ' 23:59:59'
      }

      getAccountLogList({ ...values }).then(res => {
        if(res.success) {
          this.list = this.list.concat(res.data)
        }
      })
    },
    reSearch() {
      this.page = 1
      this.list = []
      this.getAccountLogList()
    }
  },
  mounted() {
    this.getAccountLogList()
  }
}
</script>
